body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  background-color:#f9f9f9;
}

/* al eliminar bootstrap esto deberia ser innecesario */
*:focus {
  outline:none !important;
}
.mejs__offscreen, .mejs__layers, .mejs__controls  {
  display:none;
}

@keyframes loadingBlink{
  0%{background-color: rgba(204,204,204,0.5);}
  50%{background-color: rgba(204,204,204, 1);}
  100%{background-color: rgba(204,204,204, 0.5);}
}

#progress-bar > * {
  transition-duration: 0s !important;
}
.time-progress-hover:hover {
  transform: translate(-50%, -4px);
  transition: all ease-in-out 0.2s;
  width:16px;
  height:16px;
}
video::-internal-media-controls-download-button {
  display:none !important;
}
video::-webkit-media-controls-enclosure {
  overflow:hidden !important;
}
video::-webkit-media-controls-panel {
  width: calc(100% + 30px) !important; /* Adjust as needed */
}



.video-controls {
  opacity:0;
  transition:all 0.2s ease-in-out;
}
.video-controls:hover {
  opacity:1;
}
.uploading-chip {
  animation: blinker 1s ease-in-out infinite;
}
@keyframes blinker {
  50% {
    opacity: 0.3;
  }
}
.minor-btn {
  color:rgba(0, 0, 0, 0.54);
  cursor:pointer;
}
.minor-btn:hover {
  color:#000;
}

/* Spinner */
@-webkit-keyframes spin {
  from { -webkit-transform: rotate(0deg); }
  to { -webkit-transform: rotate(360deg); }
}
@keyframes spin {
  from {transform:rotate(0deg);}
  to {transform:rotate(360deg);}
}
.spinner {
  -webkit-animation-name: spin;
  -webkit-animation-duration: 40000ms;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  animation-name: spin;
  animation-duration: 40000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

/* Pulsating bars */
@-webkit-keyframes bar {
  0% { 
    transform: matrix(1, 0, 0, 0.9, 0 ,0); 
  }
  50% { 
    transform: matrix(1, 0, 0, 1.1, 0 ,0); 
  }
  100% { 
    transform: matrix(1, 0, 0, 0.9, 0 ,0); 
  }
}
@keyframes bar {
  0% { 
    transform: matrix(1, 0, 0, 0.9, 0 ,0); 
  }
  50% { 
    transform: matrix(1, 0, 0, 1.1, 0 ,0); 
  }
  100% { 
    transform: matrix(1, 0, 0, 0.9, 0 ,0); 
  }
}

.bar {
  -webkit-animation-name: bar;
  -webkit-animation-duration: 40000ms;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  animation: bar 0.4s ease-in-out;
  animation-name: bar;
  animation-duration: 40000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

.circle-graph{
  width:100px;
  height:100px;
  display:flex;
  justify-content:center;
  align-items:center;
  margin:10px 20px -20px 20px;
}
.circle-graph:before {
  content:"";
  color:white;
  height:200px;
  width:200px;
  background:transparent;
  border-radius:50%;
  border:34px solid #ccc;
  animation:loadingcirclegraph 2s infinite;
}
@keyframes loadingcirclegraph{
  50%{
    transform:rotatez(180deg) scale(0.9);
    border-style:dashed;
    border-color:#c8c8c8 #d8d8d8 #e8e8e8 #d8d8d8;
  }
  100%{
    transform:rotatez(360deg) scale(1);
  }
}
